import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import EditableField from '../EditableField';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import SectionTitle from '../SectionTitle';
import {
  Heading,
  Text,
  Icon,
  IconList,
  Link,
  PopoverTrigger,
  PopoverContent,
  Popover,
} from 'components/core';
interface Props {
  hasExtractedData?: boolean;
}

const RESERVED_FIELD_NAMES: Array<string> = ['design'];
const MAX_LENGTH = 255;
const MethodsPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const additionalFields = useFieldArray<DataExtractionTemplateState>({
    name: 'methods.additionalFields',
  });

  return (
    <section className={styles.section}>
      {modal}
      <SectionTitle
        title="Methods"
        pendoKey="det-methods"
        ariaLabel="Get help with methods"
      />
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text className={styles.PopoverTriggerText}>
          Use this section to define relevant details about the{' '}
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              design, aims and conduct of studies
            </Text>
          </PopoverTrigger>
          .
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            Study design refers to the type of study, where you’ll be able to
            select from a list of common study designs or add your own.
          </Text>
          <Text>
            Group refers to whether a study had a parallel or crossover design.
          </Text>
        </PopoverContent>
      </Popover>
      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Method details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Design</Text>
          </li>
          <li>
            <Text variant="medium">Group</Text>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Additional data
        </Heading>
        {additionalFields.fields.map((field, index) => {
          return (
            <EditableField
              key={field.id}
              index={index}
              name="methods.additionalFields"
              onDelete={() => {
                const { id } = getValues(`methods.additionalFields.${index}`);

                if (id && hasExtractedData) {
                  setModal(
                    <ConfirmationDialog
                      onConfirm={() => additionalFields.remove(index)}
                      onClose={() => setModal(null)}
                    />
                  );
                } else {
                  additionalFields.remove(index);
                }
              }}
              validate={(value, existingFields) => {
                return validate(
                  value,
                  existingFields,
                  RESERVED_FIELD_NAMES,
                  NO_EQUIVALENT_VALUE,
                  MAX_LENGTH
                );
              }}
            />
          );
        })}
        <Link
          onClick={() => additionalFields.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add additional data
        </Link>
      </div>
    </section>
  );
};

export default memo(MethodsPanel);
