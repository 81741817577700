import React from 'react';
import styles from './SingleExtractorBannerMessage.module.scss';
import {
  Badge,
  Button,
  Heading,
  Link,
  Message,
  MessageControls,
  Text,
} from 'components/core';

type Props = {
  reviewId: number;
};

const SingleExtractorBannerMessage = ({ reviewId }: Props) => {
  const reviewSettingsLink = `/reviews/${reviewId}/details`;

  return (
    <Message dismissible={false} icon={false}>
      <Heading type="h5">
        Single reviewer workflow
        <Badge variant="solid" type="primary" className={styles.earlyAccess}>
          Early access
        </Badge>
      </Heading>
      <Text>
        {`You're currently using the single reviewer workflow in Extraction 1. To
        switch to dual reviewers, please go to `}
        <Link to={reviewSettingsLink} variant="primary">
          Review Settings
        </Link>
        {`. As this is an early access release, we're actively working on improvements and would
        love your feedback to help us make it better!`}
      </Text>
      <MessageControls>
        <Button
          variant="outline"
          className={styles.giveFeedbackButton}
          data-pendo-key="single-extractor-feedback-button"
        >
          Give feedback
        </Button>
      </MessageControls>
    </Message>
  );
};

export default SingleExtractorBannerMessage;
