import React from 'react';

import styles from './Legend.module.css';
import { Stage } from './Home';

interface LegendProps {
  stage: Stage;
  complete?: number;
  partiallyComplete: number;
  consensusRequired?: number;
  notStarted: number;
}

const Legend = ({
  stage,
  complete,
  partiallyComplete,
  consensusRequired,
  notStarted,
}: LegendProps) => {
  let labels = {
    complete: 'Done',
    partiallyComplete: 'One Vote',
    consensusRequired: 'Conflicts',
    notStarted: 'No Votes',
  };

  if (stage === 'included') {
    labels = {
      ...labels,
      partiallyComplete: 'Started',
      consensusRequired: 'Consensus',
      notStarted: 'Not Started',
    };
  }

  // Check for null/undefined, a 0 value should still cause to render
  const showComplete = complete != null;
  const showConsensusRequired = consensusRequired != null;

  return (
    <div className={styles.legend}>
      {showComplete && (
        <div>
          <div className={styles.count}>{complete}</div>{' '}
          <div className={styles.description}>
            <span className={styles.completeBullet}></span>{' '}
            <span className="text">{labels['complete']}</span>
          </div>
        </div>
      )}

      {showConsensusRequired && (
        <div>
          <div className={styles.count}>{consensusRequired}</div>{' '}
          <div className={styles.description}>
            <span className={styles.consensusRequiredBullet}></span>{' '}
            <span className="text">{labels['consensusRequired']}</span>
          </div>
        </div>
      )}

      <div>
        <div className={styles.count}>{partiallyComplete}</div>{' '}
        <div className={styles.description}>
          <span className={styles.partiallyCompleteBullet}></span>{' '}
          <span className="text">{labels['partiallyComplete']}</span>
        </div>
      </div>

      <div>
        <div className={styles.count}>{notStarted}</div>{' '}
        <div className={styles.description}>
          <span className={styles.notStartedBullet}></span>{' '}
          <span className="text">{labels['notStarted']}</span>
        </div>
      </div>
    </div>
  );
};

export default Legend;
