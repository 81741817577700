/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloError, useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import idx from 'idx';
import MembershipStatus from './MembershipStatus';
import {
  AuthorQueryResult,
  GET_AUTHOR,
  GET_ASSIGNED_REVIEWS,
  GET_REVIEWS,
} from './queries';
import ErrorMessage from 'components/ErrorMessage';
import LoadingSpinner from 'system/elements/Spinner';
import { MembershipStatus as Status } from 'schema/enums';
import { ID } from 'util/types';
import useCollectionQuery from 'hooks/useCollectionQuery';

import {
  GetReviewsQuery,
  GetReviewsQueryVariables,
  GetAssignedReviewsQuery,
  GetAssignedReviewsQueryVariables,
} from 'types/graphql';
import DataTable, { Columns } from 'system/data/DataTable';
import FormattedDate from 'components/FormattedDate';
import ContentSidebar from 'system/layout/ContentSidebar';
import Text from 'system/base/Text';
import { Link } from 'system/base/Link';

const AssignedReviewsTable: FunctionComponent<{
  data?: GetAssignedReviewsQuery;
  loading: boolean;
  error?: ApolloError;
  loadMore?: () => void;
}> = ({ data, loading, error, loadMore }) => {
  const collection =
    idx(data, (_) => _.author.membership.assignedReviews.nodes) || [];

  /* eslint-disable react/display-name */
  return (
    <DataTable
      columns={
        [
          {
            heading: 'Review Title',
            primary: true,
            render: (review) => (
              <Text textStyle="truncate" title={review.title}>
                <Link href={`/reviews/${review.id}`}>{review.title}</Link>
              </Text>
            ),
          },
          {
            heading: 'Last Active',
            width: 170,
            render: (review) => (
              <FormattedDate
                date={review.lastActivityAt}
                format="relative"
                options={{ addSuffix: true }}
              />
            ),
          },
        ] as Columns<typeof collection>
      }
      collection={collection}
      loading={loading}
      error={error}
      loadMore={loadMore}
      empty="This author has no assigned reviews"
    />
  );
  /* eslint-enable react/display-name */
};

const AuthoredReviewsTable: FunctionComponent<{
  data?: GetReviewsQuery;
  loading: boolean;
  error?: Error;
  loadMore?: () => void;
}> = ({ data, loading, error, loadMore }) => {
  const collection = idx(data, (_) => _.author.membership.reviews.nodes) || [];

  /* eslint-disable react/display-name */
  return (
    <DataTable
      columns={
        [
          {
            heading: 'Review Title',
            primary: true,
            render: (review) => (
              <Text textStyle="truncate" title={review.title}>
                <Link href={`/reviews/${review.id}`}>{review.title}</Link>
              </Text>
            ),
          },
          {
            heading: 'Last Active',
            width: 170,
            render: (review) => (
              <FormattedDate
                date={review.lastActivityAt}
                format="relative"
                options={{ addSuffix: true }}
              />
            ),
          },
        ] as Columns<typeof collection>
      }
      collection={collection}
      loading={loading}
      error={error}
      loadMore={loadMore}
      empty="This author is not the author of any reviews"
    />
  );
  /* eslint-enable react/display-name */
};

interface Props {
  id: ID;
  organizationId: ID;
}

const Author = ({ id, organizationId }: Props) => {
  // Get Author
  const { data, error, loading } = useQuery<AuthorQueryResult>(GET_AUTHOR, {
    variables: {
      authorId: id,
      organizationId,
      after: null,
    },
  });

  // Get Authored Reviews
  const {
    data: authoredData,
    loading: authoredLoading,
    error: authoredError,
    loadMore: authoredLoadMore,
  } = useCollectionQuery<GetReviewsQuery, GetReviewsQueryVariables>({
    query: GET_REVIEWS,
    variables: {
      authorId: id,
      organizationId,
    },
    path: ['author', 'membership', 'reviews'],
  });

  // Get Assigned Reviews (this is conditionally rendered based on Admin)
  const {
    data: assignedData,
    loading: assignedLoading,
    error: assignedError,
    loadMore: assignedLoadMore,
  } = useCollectionQuery<
    GetAssignedReviewsQuery,
    GetAssignedReviewsQueryVariables
  >({
    query: GET_ASSIGNED_REVIEWS,
    variables: {
      authorId: id,
      organizationId,
    },
    path: ['author', 'membership', 'assignedReviews'],
  });

  // If user or authored are loading or error, don't render more
  // Assigned loading rendering is optional so if it's hanging around we'll
  // continue rendering the rest (to prevent holdups rendering non-admins)
  if (loading || authoredLoading) {
    return <LoadingSpinner />;
  }
  if (error || authoredError) {
    return <ErrorMessage>{(error! || authoredError!).message}</ErrorMessage>;
  }

  // Okay, render everything

  const invitationData = idx(data, (_) => _.author.membership.invitation);
  let invitation;
  if (invitationData) {
    invitation = {
      ...invitationData,
      expiresAt: new Date(invitationData.expiresAt),
    };
  }

  return (
    <ContentSidebar
      sidebar={
        <MembershipStatus
          currentUserId={data!.me.id}
          organization={data!.organization}
          author={data!.author}
          status={data!.author.membership.status}
          invitation={invitation}
        />
      }
    >
      {idx(data, (_) => _.author.membership.status) === Status.Admin && (
        <>
          <h2 style={{ marginTop: 0, paddingTop: 0 }}>Assigned Reviews</h2>

          <AssignedReviewsTable
            data={assignedData}
            loading={assignedLoading}
            error={assignedError}
            loadMore={assignedLoadMore}
          />

          <h2>Authored Reviews</h2>
        </>
      )}

      <AuthoredReviewsTable
        data={authoredData}
        loading={authoredLoading}
        error={authoredError}
        loadMore={authoredLoadMore}
      />
    </ContentSidebar>
  );
};

export default Author;
