import * as React from 'react';
import { useReviewFeatures } from 'hooks/reviews/useReviewFeatures';

const ReviewFeaturesContext = React.createContext<ReturnType<
  typeof useReviewFeatures
> | null>(null);

export const useReviewFeaturesContext = () => {
  const context = React.useContext(ReviewFeaturesContext);

  if (context === null) {
    throw new Error(
      'useReviewFeaturesContext called outside of a ReviewFeaturesProvider context'
    );
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const ReviewFeaturesProvider = ({ children }: Props) => {
  const reviewFeatures = useReviewFeatures();

  return (
    <ReviewFeaturesContext.Provider value={reviewFeatures}>
      {children}
    </ReviewFeaturesContext.Provider>
  );
};
