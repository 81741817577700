import React, { FunctionComponent, useState, useEffect } from 'react';
import { CreateExtractedDataExportParams } from '../index';
import styles from '../ExportToFile.module.scss';
import { UnblindedWarning } from './UnblindedWarning/UnblindedWarning';
import { IndividualReviewerDataWarning } from './IndividualReviewerDataWarning/IndividualReviewerDataWarning';
import {
  Button,
  Heading,
  Select,
  FormControl,
  FormLabel,
  FormContainer,
  RadioGroup,
  RadioButton,
} from 'components/core';

interface ExtractionFormProps {
  createExport: (type: string, params: CreateExtractedDataExportParams) => void;
  newExtraction: boolean;
  useNewTemplates: boolean;
  isSingleExtractor: boolean;
}

/* The ExportOptionValue must match the enum options in app/concepts/export/individual_reviewer_form_export.rb */
type ExportOptionValue =
  | 'all'
  | 'consensusOnly'
  | 'deConsensusOnly'
  | 'qaConsensusOnly'
  | 'deConsensusIndividualData'
  | 'qaConsensusIndividualData';

type ExportOption = {
  value: ExportOptionValue;
  description: string;
  warn: boolean;
};

const exportOptionDeConsensusOnly: ExportOption = {
  value: 'deConsensusOnly',
  description: 'Data extraction consensus only (default)',
  warn: false,
};

const exportOptionQaConsensusOnly: ExportOption = {
  value: 'qaConsensusOnly',
  description: 'Quality assessment consensus only',
  warn: false,
};

const exportOptionAll: ExportOption = {
  value: 'all',
  description: 'All data',
  warn: true,
};

const exportOptions: ExportOption[] = [
  exportOptionDeConsensusOnly,
  exportOptionQaConsensusOnly,
  {
    value: 'deConsensusIndividualData',
    description: 'Data extraction consensus + individual data',
    warn: true,
  },
  {
    value: 'qaConsensusIndividualData',
    description: 'Quality assessment consensus + individual data',
    warn: true,
  },
  {
    value: 'consensusOnly',
    description: 'Data extraction & Quality assessment consensus only ',
    warn: false,
  },
  exportOptionAll,
];

const getExportOption = (value: ExportOption['value']) => {
  const matchingOption = exportOptions.find((option) => option.value === value);
  return matchingOption ?? exportOptionAll;
};

const ExtractionForm: FunctionComponent<ExtractionFormProps> = (props) => {
  const {
    createExport,
    newExtraction,
    useNewTemplates,
    isSingleExtractor,
  } = props;
  const [format, setFormat] = useState<string>('csv');
  const [exportType, setExportType] = useState<string>('data_extraction');

  const [
    selectedExportOption,
    setSelectedExportOption,
  ] = useState<ExportOption>(
    isSingleExtractor ? exportOptionAll : exportOptionDeConsensusOnly
  );

  useEffect(() => {
    // If DE1 and user selects XLSX format, data field must change to Consensus Only
    if (!newExtraction && format === 'xlsx') {
      setSelectedExportOption(exportOptionDeConsensusOnly);
    }
  }, [format, newExtraction]);

  // Set the csv export type with a use state hook
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    /* eslint-disable @typescript-eslint/naming-convention */
    gtag('event', 'Export Extraction Data', {
      event_category: 'engagement',
      event_label: format,
    });
    createExport(exportType, {
      format,
      content_filter: selectedExportOption.value,
    });
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  const pickExportType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExportType(e.target.value);
    if (e.target.value === 'data_extraction' && !useNewTemplates) {
      // The new all-data DE export is not supported for legacy templates reviews
      setSelectedExportOption(exportOptionDeConsensusOnly);
    }
    if (e.target.value === 'quality_assessment') {
      setFormat('csv');
      setSelectedExportOption(exportOptionQaConsensusOnly);
    }
  };

  const pickFormat = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFormat(e.target.value);
  };

  const pickExportOption = (e: React.FocusEvent<HTMLSelectElement>) => {
    setSelectedExportOption(
      getExportOption(e.target.value as ExportOptionValue)
    );
  };

  const de2ExtractionContent = () => (
    /**
     * no-onchange rule is deprecated - awaiting jsx-a11y release that reflects
     * this:
     * https://github.com/bigcommerce/eslint-config/pull/53
     */
    /* eslint-disable jsx-a11y/no-onchange */
    <div>
      <div>
        <select
          style={{ textOverflow: 'ellipsis' }}
          onChange={(e) => {
            const selectedOptionValue = e.target.value as ExportOptionValue;
            const selectedOption = getExportOption(selectedOptionValue);
            setSelectedExportOption(selectedOption);
          }}
          className="form-control select"
        >
          {exportOptions.map(({ value, description }) => (
            <option key={value} value={value}>
              {description}
            </option>
          ))}
        </select>
      </div>
      <UnblindedWarning
        style={{
          visibility: selectedExportOption.warn ? 'visible' : 'hidden',
        }}
      />
    </div>
  );

  const de1ExtractionContent = () => {
    return (
      <FormContainer className={styles.optionsContainer}>
        <FormControl>
          <FormLabel htmlFor="extracted_data_type">Type</FormLabel>
          <RadioGroup
            name="extracted_data_type"
            defaultValue="data_extraction"
            onChange={pickExportType}
          >
            <RadioButton value="data_extraction">Data extraction</RadioButton>
            <RadioButton value="quality_assessment">
              Quality assessment
            </RadioButton>
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="extracted_export_data">Data</FormLabel>
          <Select
            value={selectedExportOption.value}
            className="form-control select required span4"
            id="extracted_export_data"
            onBlur={pickExportOption}
            onChange={pickExportOption}
            disabled={
              format === 'xlsx' ||
              (exportType == 'data_extraction' && !useNewTemplates) ||
              isSingleExtractor
            }
          >
            {!isSingleExtractor && (
              <option
                value={
                  exportType == 'data_extraction'
                    ? 'deConsensusOnly'
                    : 'qaConsensusOnly'
                }
              >
                Consensus only
              </option>
            )}
            {format === 'csv' && (
              <option value="all">All extracted data</option>
            )}
          </Select>
        </FormControl>
        {selectedExportOption.warn && <IndividualReviewerDataWarning />}
        <FormControl className={styles.exportFormatField}>
          <FormLabel htmlFor="extracted_export_format">Format</FormLabel>
          <Select
            value={format}
            className="form-control select required span4"
            id="extracted_export_format"
            onBlur={pickFormat}
            onChange={pickFormat}
            disabled={exportType === 'quality_assessment' || isSingleExtractor}
          >
            <option value="csv">Single sheet (CSV)</option>
            {!isSingleExtractor && (
              <option value="xlsx">Sheet per study (Excel)</option>
            )}
          </Select>
        </FormControl>
      </FormContainer>
    );
  };

  return (
    <form
      id="export-extracted-data"
      onSubmit={handleSubmit}
      className={styles.exportForm}
    >
      <div>
        <div className={styles.exportFormHeader}>
          <Heading type="h5">Extraction</Heading>
        </div>
        {newExtraction ? de2ExtractionContent() : de1ExtractionContent()}
      </div>
      <div className={styles.exportFormFooter}>
        <Button className={styles.exportFormButton} type="brand">
          Prepare file
        </Button>
      </div>
    </form>
  );
};

export default ExtractionForm;
