import { FormattedExecutionResult } from 'graphql';
import { gql, useMutation, useQuery } from '@apollo/client';

import { ID } from 'util/types';
import {
  Invite,
  InvitePersonToReviewInput,
  InvitePersonToReviewMutation,
  ReviewMembership,
  RemoveReviewerInput,
  RemoveReviewerMutation,
  CancelInvitationMutation,
  CancelInvitationInput,
} from 'types/graphql';

interface ReviewersResult {
  review: {
    owner: {
      id: ID;
    };
    invites: {
      nodes: Array<Invite>;
    };
    memberships: {
      nodes: Array<ReviewMembership>;
    };
  };
}

export const REVIEWERS_QUERY = gql`
  query ReviewersQuery($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        invites {
          nodes {
            id
            email
            name
            createdAt
            sentAt
            acceptedAt
            declinedAt
            expiresAt
            accepted
            expired
            declined
            pending
          }
        }
        memberships {
          nodes {
            createdAt
            owner
            reviewer {
              primaryEmail
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_REVIEWER = gql`
  mutation removeReviewer($reviewId: ID!, $reviewerId: ID!) {
    removeReviewer(input: { reviewId: $reviewId, reviewerId: $reviewerId }) {
      success
      errors {
        message
      }
    }
  }
`;

export const INVITE_PERSON = gql`
  mutation invitePersonToReview(
    $reviewId: ID!
    $name: String!
    $email: Email!
  ) {
    invitePersonToReview(
      input: { reviewId: $reviewId, name: $name, email: $email }
    ) {
      success
      errors {
        code
        message
      }
    }
  }
`;

export const CANCEL_INVITATION = gql`
  mutation cancelReviewerInvitation($invitationId: ID!) {
    cancelInvitation(input: { invitationId: $invitationId }) {
      success
      errors {
        message
      }
    }
  }
`;

export const useReviewersQuery = (reviewId: ID) => {
  return useQuery<ReviewersResult>(REVIEWERS_QUERY, {
    variables: {
      reviewId,
    },
  });
};

export type RemoveReviewerFn = (
  reviewerId: ID
) => Promise<FormattedExecutionResult<RemoveReviewerMutation>>;

export const useRemoveReviewerMutation = () => {
  const [removeReviewerMut] = useMutation<
    RemoveReviewerMutation,
    RemoveReviewerInput
  >(REMOVE_REVIEWER);

  return removeReviewerMut;
};

export const useInviteReviewerMutation = () => {
  const [inviteReviewerMut] = useMutation<
    InvitePersonToReviewMutation,
    InvitePersonToReviewInput
  >(INVITE_PERSON);

  return inviteReviewerMut;
};

export type CancelInviteFn = (invitationID: ID, email: string) => void;

export const useCancelInviteMutation = () => {
  const [cancelInviteMut] = useMutation<
    CancelInvitationMutation,
    CancelInvitationInput
  >(CANCEL_INVITATION);

  return cancelInviteMut;
};
