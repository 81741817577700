import React from 'react';

import styles from './Progress.module.css';

export interface ProgressProps {
  complete?: number;
  partiallyComplete: number;
  consensusRequired?: number;
  notStarted: number;
}

const Progress = ({
  complete,
  partiallyComplete,
  consensusRequired,
  notStarted,
}: ProgressProps) => {
  // Check for null/undefined, a 0 value should still cause to render
  const showComplete = complete != null;
  const showConsensusRequired = consensusRequired != null;

  const totalProgress =
    (complete || 0) + (consensusRequired || 0) + partiallyComplete + notStarted;

  const calculatePercentage = (number: number) =>
    Math.round((number / totalProgress) * 10000) / 100;

  return (
    <div className={styles.progress} data-testid="ProgressBar">
      {showComplete && (
        <Bar
          className={styles.complete}
          width={calculatePercentage(complete)}
        />
      )}
      <Bar
        className={styles.partiallyComplete}
        width={calculatePercentage(partiallyComplete)}
      />
      {showConsensusRequired && (
        <Bar
          className={styles.consensusRequired}
          width={calculatePercentage(consensusRequired)}
        />
      )}
      <Bar
        className={styles.notStarted}
        width={calculatePercentage(notStarted)}
      />
    </div>
  );
};

const Bar = (props: any) => {
  return (
    <div
      className={`${props.className} ${styles.bar}`}
      style={{ width: `${props.width}%` }}
    />
  );
};

export default Progress;
