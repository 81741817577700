import React, { memo } from 'react';
import { useFieldArray, UseFieldArrayReturn } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import {
  Heading,
  Icon,
  IconList,
  Link,
  Button,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Text,
  PopoverTrigger,
  PopoverContent,
  Popover,
} from '../../../../../components/core';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import { DataExtractionTemplateResultType } from '../../../../../types';
import SectionTitle from '../SectionTitle';
import resultDataStyles from './ResultsDataPanel.module.scss';
import CustomResultTypeEditor from './CustomResultTypeEditor';
import {
  dataFormat,
  newContinuousDataFormats,
  legacyContinuousDataFormats,
  legacyDichotomousDataFormats,
  newDichotomousDataFormats,
} from './ResultDataFormats';
import { useReviewFeatures } from 'hooks/reviews/useReviewFeatures';
import { OutcomeTypes } from 'types/DataExtraction';

const chipButton = (format: dataFormat, key: string) => {
  return (
    <Tooltip key={key}>
      <TooltipTrigger>
        <Button
          className={resultDataStyles.chipButton}
          data-testid="chipButton"
          size="xs"
          isNaked={true}
        >
          {format.name}
        </Button>
      </TooltipTrigger>
      <TooltipContent>{format.tooltip}</TooltipContent>
    </Tooltip>
  );
};

type Props = {
  resultsDataAnchor?: string; // NB: ID used for anchor from OutcomeEditor (View list of data formats)
};

const ResultsDataPanel = ({ resultsDataAnchor }: Props) => {
  const { hasSelectableMeasures } = useReviewFeatures();

  const continuousData = hasSelectableMeasures
    ? newContinuousDataFormats
    : legacyContinuousDataFormats;

  const dichotomousData = hasSelectableMeasures
    ? newDichotomousDataFormats
    : legacyDichotomousDataFormats;

  const customResultTypes = useFieldArray<DataExtractionTemplateState>({
    name: 'custom_result_types',
  }) as UseFieldArrayReturn<DataExtractionTemplateState, 'custom_result_types'>;
  const deleteCustomResultType = (
    resultType: DataExtractionTemplateResultType,
    index: number
  ) => {
    if (!resultType.used) customResultTypes.remove(index);
  };
  const addCustomDichotomous = () => {
    customResultTypes.append({
      outcome_type: OutcomeTypes.DICHOTOMOUS,
      label: '',
    });
  };
  const addCustomContinuous = () => {
    customResultTypes.append({
      outcome_type: OutcomeTypes.CONTINUOUS,
      label: '',
    });
  };

  return (
    <section id={resultsDataAnchor} className={styles.section}>
      <SectionTitle
        title="Results data"
        pendoKey={
          hasSelectableMeasures
            ? 'det-selectable-results-data'
            : 'det-results-data'
        }
        ariaLabel="Get help with results data"
      />
      {hasSelectableMeasures ? (
        <Popover closeOnBlur={true} renderCloseButton={false}>
          <Text
            className={styles.PopoverTriggerText}
            data-testid="result-popover-text"
          >
            Use this section to{' '}
            <PopoverTrigger>
              <Text component="span" className={styles.PopoverTrigger}>
                view and add custom data
              </Text>
            </PopoverTrigger>{' '}
            formats.
          </Text>
          <PopoverContent className={styles.PopoverContentText}>
            <Text>
              Results data allows you to analyse and synthesise data about an
              outcome.
            </Text>
            <Text>
              All options shown below can be selected during extraction.
            </Text>
            <Text>
              You can also add custom data. For example, when collecting
              qualitative data you might need “Findings”, “Themes”, “Quote”.
            </Text>
          </PopoverContent>
        </Popover>
      ) : (
        <Text className={resultDataStyles.section}>
          You can select any of these data formats in each study:
        </Text>
      )}
      <div className={resultDataStyles.section}>
        <Heading type="h5" component="h3" variant="neutral">
          Continuous data
        </Heading>
        <Text className={resultDataStyles.divider}>
          {hasSelectableMeasures &&
            'Numerical data that can take any value within a range.'}
        </Text>
        <Heading
          type="h6"
          component="h4"
          variant="neutral"
          className={resultDataStyles.smallDivider}
        >
          Standard
        </Heading>
        {continuousData.map((format, index) =>
          chipButton(format, `continuous_${index + 1}`)
        )}
        {hasSelectableMeasures && (
          <div className={resultDataStyles.customResultTypesSection}>
            <Heading type="h6" component="h4" variant="neutral">
              Custom
            </Heading>
            <div className={resultDataStyles.smallDivider}>
              {customResultTypes.fields.map((field, index) => {
                if (field.outcome_type == OutcomeTypes.CONTINUOUS)
                  return (
                    <CustomResultTypeEditor
                      key={field.id}
                      index={index}
                      resultType={field}
                      onDelete={() => deleteCustomResultType(field, index)}
                    />
                  );
              })}
            </div>
            <Link onClick={addCustomContinuous} variant="primary">
              <Icon icon={IconList.light.faPlus} /> Add custom
            </Link>
          </div>
        )}
      </div>
      <div className={resultDataStyles.section}>
        <Heading type="h5" component="h3" variant="neutral">
          Dichotomous data
        </Heading>
        <Text className={resultDataStyles.divider}>
          {hasSelectableMeasures &&
            'Also known as binary data that has only two possibilities, e.g. yes or no.'}
        </Text>
        <Heading
          type="h6"
          component="h4"
          variant="neutral"
          className={resultDataStyles.smallDivider}
        >
          Standard
        </Heading>
        {dichotomousData.map((format, index) =>
          chipButton(format, `dichotomous_${index + 1}`)
        )}
        {hasSelectableMeasures && (
          <div className={resultDataStyles.customResultTypesSection}>
            <Heading type="h6" component="h4" variant="neutral">
              Custom
            </Heading>
            <div className={resultDataStyles.smallDivider}>
              {customResultTypes.fields.map((field, index) => {
                if (field.outcome_type == OutcomeTypes.DICHOTOMOUS)
                  return (
                    <CustomResultTypeEditor
                      key={field.id}
                      index={index}
                      resultType={field}
                      onDelete={() => deleteCustomResultType(field, index)}
                    />
                  );
              })}
            </div>
            <Link onClick={addCustomDichotomous} variant="primary">
              <Icon icon={IconList.light.faPlus} /> Add custom
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default memo(ResultsDataPanel);
