import React from 'react';

import classNames from 'classnames';
import styles from './Action.module.css';

import { Stage } from './Home';
import { PrimaryLink, TertiaryLink } from 'system/base/Link';
import { useReviewFeaturesContext } from 'contexts/ReviewFeaturesProvider';

interface ActionProps {
  title: string;
  url: string;
  stage: Stage;
  count: number;
  text: string;
  type: 'primary' | 'tertiary';
  dataTestId?: string;
}

const Action = ({
  stage,
  url,
  title,
  count,
  text,
  type,
  dataTestId,
}: ActionProps) => {
  const { getSetting } = useReviewFeaturesContext();
  const isSingleExtractor =
    getSetting('structured_extraction_users_required') === 1;
  const isExtractionPanel = stage === 'included';

  const LinkComponent = type === 'primary' ? PrimaryLink : TertiaryLink;
  return (
    <div>
      {isExtractionPanel && isSingleExtractor ? (
        <>
          <div className={styles.count}>{count}</div>
          <div className={styles.singleExtractorCountFooter}>
            studies to extract
          </div>
        </>
      ) : (
        <>
          <div className={styles.title}>{title}</div>
          <div className={styles.count}>{count}</div>
        </>
      )}
      <div
        className={classNames({
          [styles.singleExtractorActionContainer]:
            isExtractionPanel && isSingleExtractor,
        })}
      >
        <LinkComponent
          className={styles.button}
          href={url}
          data-testid={dataTestId ? dataTestId : `stage-action-${stage}`}
        >
          {text}
        </LinkComponent>
      </div>
    </div>
  );
};

export default Action;
