import React from 'react';
import styles from '../../DataExtractionTemplate.module.scss';
import { Popover, PopoverContent, PopoverTrigger, Text } from 'components/core';

const InterventionPopoversList = () => {
  return (
    <>
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text component="span">
          Use this section to define relevant{' '}
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              interventions
            </Text>
          </PopoverTrigger>
          ,{' '}
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            Interventions can be drugs, treatments or therapies, medication,
            physical therapy, surgical procedures, lifestyle changes, policies,
            etc.
          </Text>
          <Text>
            <strong>Examples</strong>: Educational programmes, hydrotherapy, hip
            replacement, a particular drug.
          </Text>
        </PopoverContent>
      </Popover>
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text component="span">
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              comparators
            </Text>
          </PopoverTrigger>
          ,{' '}
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            Comparator is the standard or reference point against which the
            intervention or exposure is being evaluated.
          </Text>
          <Text>
            <strong>Examples</strong>: control, placebo, usual treatment, usual
            care, no treatment, different treatment.
          </Text>
        </PopoverContent>
      </Popover>
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text component="span">
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              exposures
            </Text>
          </PopoverTrigger>
          ,{' '}
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            Exposures can be lifestyle factors, risk factors, population factors
            etc. Evaluating exposures can help in determining the cause and
            treatment of particular outcomes.
          </Text>
          <Text>
            <strong>Examples</strong>: smoking, air pollution, a particular
            drug, treatment or therapy.
          </Text>
        </PopoverContent>
      </Popover>
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text component="span">
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              concepts or phenomenons of interest
            </Text>
          </PopoverTrigger>
          .
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            Concepts or phenomena of interest is the topic of interest for your
            review question.
          </Text>
          <Text>
            <strong>Examples</strong>: travel, mindfulness, new policy, rest.
          </Text>
        </PopoverContent>
      </Popover>
    </>
  );
};
export default InterventionPopoversList;
