import React, { useState } from 'react';
import { UseFieldArrayReturn, useFieldArray } from 'react-hook-form';
import styles from '../DefaultTemplate.module.scss';
import QaConfirmationDialog from '../../QaConfirmationDialog';
import { QualityAssessmentTemplateState } from '../../QualityAssessmentTemplate';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import EditableField from './EditableField';
import { Icon, IconList, Link } from 'components/core';
import SectionTitle from 'pages/DataExtraction/DataExtractionTemplate/components/SectionTitle';

const RESERVED_FIELD_NAMES = ['all outcomes'];
const MAX_OUTCOME_GROUP_NAME_LENGTH = 1000;

const OutcomeGroupsSection = () => {
  const outcomeGroups = useFieldArray({
    name: 'outcome_groups',
  }) as UseFieldArrayReturn<QualityAssessmentTemplateState, 'outcome_groups'>;

  const [modal, setModal] = useState<React.ReactElement | null>();

  return (
    <section className={styles.DefaultTemplate_section}>
      {modal}
      <SectionTitle
        title="Outcome groups"
        pendoKey="qat-outcome-groups"
        ariaLabel="Get help with outcome groups"
      />
      <ul>
        {outcomeGroups.fields.map((field, index) => {
          return (
            <li key={field.id}>
              <EditableField
                index={index}
                name={`outcome_groups`}
                onDelete={() => {
                  if (field.prompt_before_deleting) {
                    setModal(
                      <QaConfirmationDialog
                        onConfirm={() => outcomeGroups.remove(index)}
                        onClose={() => setModal(null)}
                      />
                    );
                    return;
                  }
                  outcomeGroups.remove(index);
                }}
                validate={(value, existingFields) => {
                  return validate(
                    value,
                    existingFields,
                    RESERVED_FIELD_NAMES,
                    NO_EQUIVALENT_VALUE,
                    MAX_OUTCOME_GROUP_NAME_LENGTH
                  );
                }}
              />
            </li>
          );
        })}
      </ul>

      <Link
        onClick={() =>
          outcomeGroups.append({ name: '', prompt_before_deleting: false })
        }
        className={styles.SectionButton}
        variant="primary"
      >
        <Icon icon={IconList.light.faPlus} /> Add group
      </Link>
    </section>
  );
};

export default OutcomeGroupsSection;
