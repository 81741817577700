import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import EditableField from '../EditableField';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import SectionTitle from '../SectionTitle';
import InterventionPopoversList from './InterventionPopoversList';
import {
  Heading,
  Icon,
  IconList,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from 'components/core';
interface Props {
  hasExtractedData?: boolean;
}

const InterventionsPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const interventions = useFieldArray<DataExtractionTemplateState>({
    name: 'interventions.interventions',
  });
  const characteristics = useFieldArray<DataExtractionTemplateState>({
    name: 'interventions.characteristics',
  });
  const MAX_LENGTH = 255;

  return (
    <section className={styles.section}>
      {modal}
      <SectionTitle
        title="Interventions"
        pendoKey="det-interventions"
        ariaLabel="Get help with interventions"
      />
      <InterventionPopoversList />
      <div className={styles.details} data-testid="interventions">
        <Heading type="h5" component="h4" variant="neutral">
          Interventions
        </Heading>
        <Popover closeOnBlur={true} renderCloseButton={false}>
          <Text>
            During extraction, you can{' '}
            <PopoverTrigger>
              <Text component="span" className={styles.PopoverTrigger}>
                select and customise
              </Text>
            </PopoverTrigger>{' '}
            interventions for each study.
          </Text>
          <PopoverContent className={styles.PopoverContentText}>
            <Text>
              You only need to extract data for interventions that are relevant
              to each study.
            </Text>
            <Text>
              When extracting, you can also create multiple groups associated
              with each intervention. This is useful when there are multiple
              study groups reported or if you want to create subgroups for a
              subgroup analysis.
            </Text>
          </PopoverContent>
        </Popover>
        {interventions.fields.map((field, index) => {
          return (
            <EditableField
              key={field.id}
              index={index}
              name="interventions.interventions"
              onDelete={() => {
                const { id } = getValues(
                  `interventions.interventions.${index}`
                );

                if (id && hasExtractedData) {
                  setModal(
                    <ConfirmationDialog
                      onConfirm={() => interventions.remove(index)}
                      onClose={() => setModal(null)}
                    />
                  );
                } else {
                  interventions.remove(index);
                }
              }}
              validate={(value, existingFields) => {
                return validate(
                  value,
                  existingFields,
                  [],
                  NO_EQUIVALENT_VALUE,
                  MAX_LENGTH
                );
              }}
            />
          );
        })}

        <Link
          onClick={() => interventions.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add intervention
        </Link>
      </div>
      <div className={styles.details} data-testid="characteristics">
        <Heading type="h5" component="h4" variant="neutral">
          Characteristics
        </Heading>
        <Popover closeOnBlur={true} renderCloseButton={false}>
          <Text>
            Define{' '}
            <PopoverTrigger>
              <Text component="span" className={styles.PopoverTrigger}>
                characteristics
              </Text>
            </PopoverTrigger>{' '}
            you want to collect for each intervention.
          </Text>
          <PopoverContent className={styles.PopoverContentText}>
            <Text>
              During extraction, a table will be created with a row for each
              characteristic and a column for each group reported in the study.
            </Text>
            <Text>
              Collecting this data will allow you to compare the differences
              between interventions.
            </Text>
            <Text>
              <strong>For example</strong>: Description of the treatment /
              exposure
            </Text>
          </PopoverContent>
        </Popover>
        {characteristics.fields.map((field, index) => {
          return (
            <EditableField
              key={field.id}
              index={index}
              name="interventions.characteristics"
              onDelete={() => {
                if (hasExtractedData) {
                  setModal(
                    <ConfirmationDialog
                      onConfirm={() => characteristics.remove(index)}
                      onClose={() => setModal(null)}
                    />
                  );
                } else {
                  characteristics.remove(index);
                }
              }}
              validate={(value, existingFields) => {
                return validate(
                  value,
                  existingFields,
                  [],
                  NO_EQUIVALENT_VALUE,
                  MAX_LENGTH
                );
              }}
            />
          );
        })}
        <Link
          onClick={() => characteristics.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add characteristic
        </Link>
      </div>
    </section>
  );
};

export default memo(InterventionsPanel);
