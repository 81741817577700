import React, { ChangeEvent, useState } from 'react';
import styles from './AutomationOptions.module.scss';
import { Checkbox, Text, Link } from 'components/core';

export type CommonAutomationOptionsProps = {
  isSuggestionsEnabled: boolean;
};

export const CommonAutomationOptions = ({
  isSuggestionsEnabled,
}: CommonAutomationOptionsProps): JSX.Element => {
  const [enableSuggestions, setEnableSuggestions] = useState(
    isSuggestionsEnabled
  );

  const handleSuggestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnableSuggestions(event.currentTarget.checked);
  };

  return (
    <>
      <Text className={styles.checkboxDescription}>
        Use{' '}
        <Link
          external
          variant="primary"
          to="https://support.covidence.org/help/how-to-set-up-the-interventions-section-on-the-data-extraction-template#extracting-data-from-studies"
        >
          large language models and metadata
        </Link>
        to:
      </Text>

      <Checkbox
        name="enable_suggestions"
        value="yes"
        checked={enableSuggestions}
        onChange={handleSuggestionChange}
      >
        Provide suggestions during data extraction (available for Extraction 1
        only)
      </Checkbox>
    </>
  );
};
