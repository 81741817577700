import React from 'react';
import styles from './SectionTitle.module.scss';
import { Button, Icon, Text, Heading, IconList } from 'components/core';

interface Props {
  title: string;
  pendoKey: string;
  ariaLabel: string;
}

export const SectionTitle = ({ title, pendoKey, ariaLabel }: Props) => {
  return (
    <Heading type="h4" component="h3" variant="brand" className={styles.Title}>
      {title}
      <Button
        size="sm"
        variant="outline"
        onClick={() => ''}
        data-pendo-key={pendoKey}
        aria-label={ariaLabel}
      >
        <Text size="sm">More info</Text>
        <Icon size="lg" icon={IconList.light.faCircleInfo} />
      </Button>
    </Heading>
  );
};

export default SectionTitle;
