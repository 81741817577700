import React from 'react';
import SingleExtractorModal from 'pages/ReviewSettings/components/ExtractionSelector/components/SingleExtractorModal';
import { updateStructuredExtractionUsers } from 'query/review/Details';
import { createToast, Toast, ToastContainer } from 'components/core';

type Props = {
  reviewId: string;
};

const SingleExtractorModalPrompt = ({ reviewId }: Props) => {
  const [modal, setModal] = React.useState(true);
  const hasBeenSeen = `review_${reviewId}_seen_single_extractor_modal`;
  const singleExtractorWorkflow = 1;

  if (localStorage.getItem(hasBeenSeen)) {
    return null;
  }

  const handleConfirm = () => {
    updateStructuredExtractionUsers({
      reviewId,
      usersRequired: singleExtractorWorkflow,
    })
      .then(() => {
        setModal(false);
        localStorage.setItem(hasBeenSeen, 'true');
        window.location.reload();
      })
      .catch((error) => {
        setModal(false);
        localStorage.setItem(hasBeenSeen, 'true');
        console.error(
          'Error updating reviewers required for data extraction',
          error
        );
        createToast(
          <Toast type="danger">
            Error updating reviewers required for data extraction
          </Toast>
        );
      });
  };

  const handleClose = () => {
    localStorage.setItem(hasBeenSeen, 'true');
    setModal(false);
  };

  return (
    <>
      {modal && (
        <SingleExtractorModal onConfirm={handleConfirm} onClose={handleClose} />
      )}
      <ToastContainer />
    </>
  );
};

export default SingleExtractorModalPrompt;
