import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import EditableField from '../EditableField';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import SectionTitle from '../SectionTitle';
import {
  Heading,
  Text,
  Icon,
  IconList,
  Link,
  PopoverTrigger,
  PopoverContent,
  Popover,
} from 'components/core';
const RESERVED_FIELD_NAMES: Array<string> = [
  'inclusion criteria',
  'exclusion criteria',
  'group differences',
];

interface Props {
  hasExtractedData?: boolean;
}

const PopulationPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const additionalFields = useFieldArray<DataExtractionTemplateState>({
    name: 'population.additionalFields',
  });
  const baselineCharacteristics = useFieldArray<DataExtractionTemplateState>({
    name: 'population.baseline_characteristics',
  });
  const MAX_LENGTH = 255;

  return (
    <section className={styles.section}>
      {modal}
      <SectionTitle
        title="Population"
        pendoKey="det-population"
        ariaLabel="Get help with population"
      />
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text className={styles.PopoverTriggerText}>
          Use this section to define relevant details about the{' '}
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              people included in the study
            </Text>
          </PopoverTrigger>
          .
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            This includes eligibility, differences between groups and baseline
            characteristics.
          </Text>
          <Text>
            When extracting baseline characteristics from a study, you’ll be
            able to extract data for each group of people or for the overall
            population.
          </Text>
        </PopoverContent>
      </Popover>
      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Population details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Inclusion criteria</Text>
          </li>
          <li>
            <Text variant="medium">Exclusion criteria</Text>
          </li>
          <li>
            <Text variant="medium">Group differences</Text>
          </li>
        </ul>
      </div>
      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Additional data
        </Heading>
        {additionalFields.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="population.additionalFields"
            onDelete={() => {
              const { id } = getValues(`population.additionalFields.${index}`);

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => additionalFields.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                additionalFields.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                RESERVED_FIELD_NAMES,
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => additionalFields.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add additional data
        </Link>
      </div>
      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Baseline characteristics
        </Heading>
        <Popover closeOnBlur={true} renderCloseButton={false}>
          <Text className={styles.PopoverTriggerText}>
            Define baseline characteristics you want to{' '}
            <PopoverTrigger>
              <Text component="span" className={styles.PopoverTrigger}>
                collect for each group of people in the study
              </Text>
            </PopoverTrigger>
            .
          </Text>
          <PopoverContent className={styles.PopoverContentText}>
            <Text>
              When extracting data from studies, a table will be created with a
              row for each baseline characteristic and a column for each group
              reported in the study.
            </Text>
          </PopoverContent>
        </Popover>
        {baselineCharacteristics.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="population.baseline_characteristics"
            onDelete={() => {
              const { id } = getValues(
                `population.baseline_characteristics.${index}`
              );

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => baselineCharacteristics.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                baselineCharacteristics.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                [],
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => baselineCharacteristics.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add baseline characteristic
        </Link>
      </div>
    </section>
  );
};

export default memo(PopulationPanel);
