import { ApolloError, MaybeMasked, OperationVariables } from '@apollo/client';
import {
  Dispatch,
  Reducer,
  ReducerAction,
  useCallback,
  useReducer,
} from 'react';
import { updateIn } from 'timm';
import usePaginatedQuery, { LoadMoreFn } from 'hooks/usePaginatedQuery';

interface Loading<T> {
  loading: true;
  data?: MaybeMasked<T>;
  error?: never;
  loadMore?: never;
}

interface Loaded<T, TVariables> {
  loading: false;
  error?: never;
  data: MaybeMasked<T>;
  loadMore?: LoadMoreFn<T, TVariables>;
}

interface Error<T> {
  loading: false;
  error: ApolloError;
  data?: MaybeMasked<T>;
  loadMore?: never;
}

export type SetByKeyFn<T> = (
  key: string | string[],
  value: T[keyof T] | any
) => void;

type VariablesReducerAction<T> = ((prev: T) => T) | Partial<T>;
type VariablesReducer<T> = Reducer<T, VariablesReducerAction<T>>;
export type SetVariablesFn<T> = Dispatch<ReducerAction<VariablesReducer<T>>>;

interface UseCollectionQueryArgs<TVariables = Record<string, unknown>> {
  query: Parameters<typeof usePaginatedQuery>[0];
  variables: TVariables;
  path: string[]; // PathOf<TQuery>
}

type CollectionQueryResultCommon<T, TVariables> =
  | Loading<T>
  | Loaded<T, TVariables>
  | Error<T>;

interface CollectionQueryResultStateful<TVariables = Record<string, unknown>> {
  variables: TVariables;
  setVariable: SetByKeyFn<TVariables>;
  setVariables: SetVariablesFn<TVariables>;
}

type CollectionQueryResult<
  TQuery,
  TVariables
> = CollectionQueryResultStateful<TVariables> &
  CollectionQueryResultCommon<TQuery, TVariables>;

function reduceVariables<T>(state: T, action: VariablesReducerAction<T>) {
  if (typeof action === 'function') {
    return action(state);
  } else {
    return {
      ...state,
      ...action,
    };
  }
}

function useCollectionQuery<TQuery, TVariables extends OperationVariables>({
  query,
  variables: initialVariables,
  path, // pagination path
}: UseCollectionQueryArgs<TVariables>): CollectionQueryResult<
  TQuery,
  TVariables
> {
  const [variables, setVariables] = useReducer<VariablesReducer<TVariables>>(
    reduceVariables,
    initialVariables
  );

  const setVariable = useCallback<SetByKeyFn<TVariables>>(
    // if value is undefined, we won't remove the key but update its value to undefined
    (path, value) => {
      const arrPath = typeof path === 'string' ? [path] : path;

      // TODO: use similar typing to updateIn for the type of path, avoid any
      return setVariables((prev) => updateIn(prev, arrPath, () => value));
    },
    [setVariables]
  );

  const { data, error, loading, loadMore } = usePaginatedQuery<
    TQuery,
    TVariables
  >(query, {
    paginate: path,
    variables: {
      after: null,
      ...variables,
    },
  });

  const result: CollectionQueryResultStateful<TVariables> = {
    variables,
    setVariable,
    setVariables,
  };

  if (error) {
    // error state is the end of the road
    return {
      loading: false,
      data,
      error,
      ...result,
    };
  }

  if (loading || !data) {
    return {
      loading: true,
      data,
      ...result,
    };
  }

  return {
    loading,
    data,
    loadMore,
    ...result,
  };
}

export default useCollectionQuery;
