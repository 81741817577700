import React, { memo, useState } from 'react';
import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import ConfirmationDialog from '../ConfirmationDialog';
import SectionTitle from '../SectionTitle';
import AddOutcome from './AddOutcome/AddOutcome';
import outcomeStyles from './OutcomesPanel.module.scss';
import OutcomeEditor from './OutcomeEditor';
import { OutcomeTypes } from 'types/DataExtraction';
import {
  Heading,
  Accordion,
  Text,
  PopoverTrigger,
  PopoverContent,
  Popover,
} from 'components/core';

type Props = {
  resultsDataAnchor?: string;
};
const OutcomesPanel = ({ resultsDataAnchor }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const outcomes = useFieldArray<DataExtractionTemplateState>({
    name: 'outcomes.outcomes',
  }) as UseFieldArrayReturn<DataExtractionTemplateState, 'outcomes.outcomes'>;
  const { getValues, formState } = useFormContext();

  const onAddOutcome = () => {
    outcomes.append({
      name: '',
      outcome_type: OutcomeTypes.CONTINUOUS,
      timepoints: [{ name: 'Baseline' }],
      has_extracted_data: false,
    });
  };

  return (
    <section className={styles.section}>
      {modal}
      <SectionTitle
        title="Outcomes"
        pendoKey="det-outcomes"
        ariaLabel="Get help with outcomes"
      />
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text className={styles.PopoverTriggerText} component="span">
          Use this section to define relevant{' '}
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              outcomes
            </Text>
          </PopoverTrigger>
          ,{' '}
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            Add the specific outcomes you are interested in investigating, which
            can be continuous or dichotomous.
          </Text>
          <Text>
            If you need both, we suggest to add two different outcomes as this
            will make it easier to analyse and synthesise the data later.
          </Text>
        </PopoverContent>
      </Popover>
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text className={styles.PopoverTriggerText} component="span">
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              themes or evaluations
            </Text>
          </PopoverTrigger>
          .
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            If you&apos;re looking to extract data for a qualitative outcome
            like themes or evaluations, you can add an outcome which is either
            continuous or dichotomous and use custom data types to extract
            qualitative data.
          </Text>
        </PopoverContent>
      </Popover>
      <div className={outcomeStyles.outcomeDetailsSection}>
        <Heading type="h5" component="h4" variant="neutral">
          Outcomes
        </Heading>

        <Accordion component="div" allowMultiple={false}>
          {outcomes.fields.map((field, index) => {
            return (
              <OutcomeEditor
                key={field.id}
                index={index}
                resultsDataAnchor={resultsDataAnchor}
                hasExtractedData={field.has_extracted_data}
                onDelete={() => {
                  const { id, has_extracted_data } = getValues(
                    `outcomes.outcomes.${index}`
                  );

                  if (id && has_extracted_data) {
                    setModal(
                      <ConfirmationDialog
                        onConfirm={() => outcomes.remove(index)}
                        onClose={() => setModal(null)}
                      />
                    );
                  } else {
                    outcomes.remove(index);
                  }
                }}
              />
            );
          })}
        </Accordion>

        <AddOutcome
          onClick={onAddOutcome}
          disabled={!formState.isValid}
          length={outcomes.fields.length}
        />
      </div>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Outcome details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Outcome reporting</Text>
          </li>
          <li>
            <Text variant="medium">Scale</Text>
          </li>
          <li>
            <Text variant="medium">Range</Text>
          </li>
          <li>
            <Text variant="medium">Unit of measurement</Text>
          </li>
          <li>
            <Text variant="medium">Direction</Text>
          </li>
          <li>
            <Text variant="medium">Data value</Text>
          </li>
          <li>
            <Text variant="medium">Notes</Text>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default memo(OutcomesPanel);
