import React, {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  useRef,
  useState,
} from 'react';
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  flip,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
  Placement,
} from '@floating-ui/react';
import classNames from 'classnames';
import { Props as InputProps } from '../Input/Input';
import styles from './ComboBox.module.scss';
import { Button, Input, Text } from 'components/core';
import { Stack } from 'components/layout';

interface ListItemProps {
  children: React.ReactNode;
  isActive: boolean;
}

const ListItem = forwardRef<HTMLButtonElement, ListItemProps>(
  ({ children, isActive, ...rest }, ref) => {
    const id = useId();

    return (
      <Button
        isNaked
        role="option"
        ref={ref}
        id={id}
        size="sm"
        aria-selected={isActive}
        className={classNames(styles.ListItem, {
          [styles.Active]: isActive,
        })}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);
ListItem.displayName = 'ListItem';

export interface ComboBoxProps extends InputProps {
  data?: string[];
  value: string;
  listHeading?: string;
  placement?: Placement;
  onChange: (value: string | ChangeEvent<HTMLInputElement>) => void;
}

const ComboBox = (
  {
    data = [],
    value,
    placeholder,
    placement = 'bottom-start',
    readOnly,
    listHeading,
    onFocus,
    onChange,
    onBlur,
    ...rest
  }: ComboBoxProps,
  passedInRef: ForwardedRef<HTMLInputElement>
) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [activeListItemIndex, setActiveListItemIndex] = useState<number | null>(
    null
  );

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { context, refs, floatingStyles } = useFloating<HTMLInputElement>({
    placement,
    // Ensure floating element remains anchored to the reference element
    whileElementsMounted: autoUpdate,
    open: isListOpen,
    onOpenChange: setIsListOpen,
    // Functions to apply to floating element
    middleware: [
      offset(2), // Gap between floating and reference element
      flip({ padding: 10 }), // Change placement of the floating element to keep it in view.
      size({
        apply({ rects, availableHeight, availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`, // Same size as reference element
            maxWidth: `${availableWidth}px`, // Ensure menu not wider than width of viewport
            maxHeight: `${availableHeight}px`, // Ensure menu not taller than height of viewport
          });
        },
      }),
    ],
  });

  const mergedReferenceRefs = useMergeRefs([passedInRef, refs.setReference]);
  // Automatically generate screen reader props for reference and floating element
  const role = useRole(context, { role: 'listbox' });
  // Closes the floating element when a dismissal is requested
  const dismiss = useDismiss(context);
  // Adds arrow key-based navigation of a list of items
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex: activeListItemIndex,
    onNavigate: setActiveListItemIndex,
    virtual: true,
    loop: true,
    allowEscape: true,
  });

  // Merge/compose interaction event handlers together
  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useInteractions([role, dismiss, listNavigation]);

  const handleValueChange = (value: string) => {
    setInputValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (!readOnly && !isListOpen) {
      setIsListOpen(true);
    }

    if (isListOpen) {
      setIsListOpen(false);
    }

    if (onFocus) {
      onFocus(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<Element>) => {
    // Autocomplete text for user when they hit enter
    if (
      event.key === 'Enter' &&
      activeListItemIndex !== null &&
      data[activeListItemIndex]
    ) {
      event.preventDefault();
      setActiveListItemIndex(null);
      setIsListOpen(false);
      handleValueChange(data[activeListItemIndex]);
    }
  };

  const handleChange = (value: string) => {
    handleValueChange(value);

    if (value) {
      setIsListOpen(true);
      const index = data.findIndex((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setActiveListItemIndex(index);
    } else {
      setActiveListItemIndex(0);
      setIsListOpen(false);
    }
  };

  return (
    <>
      <Input
        {...rest}
        ref={mergedReferenceRefs}
        {...getReferenceProps({
          'aria-autocomplete': 'list',
          // Prevent Chrome from adding it's own autocomplete popup
          autoComplete: 'off',
          readOnly,
          placeholder,
          value: inputValue,
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: (e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e.currentTarget.value),
          onKeyDown: handleKeyDown,
        })}
      />

      <FloatingPortal>
        {isListOpen && (
          <FloatingFocusManager
            context={context}
            // By default the users focus is moved into the floating element and passing -1 disables this behaviour
            // We want focus to remain in the input, so users can continue typing
            initialFocus={-1}
            // Renders a visually-hidden dismiss button for screen readers
            visuallyHiddenDismiss
          >
            <div
              ref={refs.setFloating}
              {...getFloatingProps({
                className: classNames(styles.List, {
                  // Avoids adding a thin white line when there are no items
                  [styles.ListHasItems]: Boolean(data.length),
                }),
                style: {
                  ...floatingStyles,
                },
              })}
            >
              {listHeading && data.length > 0 && (
                <Text
                  className={styles.ListHeading}
                  size="xs"
                  variant="weak"
                  component="h3"
                >
                  {listHeading}
                </Text>
              )}
              <Stack>
                {data.map((item, index) => (
                  <ListItem
                    key={item}
                    {...getItemProps({
                      ref(node) {
                        listRef.current[index] = node;
                      },
                      onClick() {
                        setInputValue(item);

                        handleChange(item);

                        // Move users cursor back to the input
                        refs.domReference.current?.focus();
                      },
                    })}
                    isActive={activeListItemIndex === index}
                  >
                    {item}
                  </ListItem>
                ))}
              </Stack>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

const forwardedComboBox = forwardRef<HTMLInputElement, ComboBoxProps>(ComboBox);
export default forwardedComboBox;
export { forwardedComboBox as ComboBox };
