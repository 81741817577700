import React from 'react';
import StageSummary from './StageSummary';
import { Statistics } from './Home';
import { CategoryLink } from './categoryLink';

interface TitleAndAbstractProps {
  reviewerName: string;
  irrelevant: number;
  stats: Statistics;
  isOpen: boolean;
}

const TitleAndAbstract = ({
  irrelevant,
  reviewerName,
  stats,
  isOpen,
}: TitleAndAbstractProps) => {
  const secondaryContent = `${irrelevant} irrelevant`;
  const studyCategoryCount = `${stats?.available} ${
    stats?.available === 1 ? 'study' : 'studies'
  } to screen`;
  return (
    <StageSummary
      isOpen={isOpen}
      stage="screen"
      title="Title and abstract screening"
      secondaryLink={CategoryLink('irrelevant')}
      secondaryContent={secondaryContent}
      studyCategoryCount={studyCategoryCount}
      studyCategoryLink={CategoryLink('screen')}
      permissions={{ consensusRequired: true }}
      reviewerName={reviewerName}
      stats={stats}
    />
  );
};

export default TitleAndAbstract;
