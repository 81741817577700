import { getCsrfToken } from 'query';
import { REVIEW_DETAILS, tokenizeRoute } from 'query/routes';

interface UpdateStructuredExtractionUsers {
  reviewId: string;
  usersRequired: number;
}

export const updateStructuredExtractionUsers = async ({
  reviewId,
  usersRequired,
}: UpdateStructuredExtractionUsers) => {
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(REVIEW_DETAILS, {
    review_id: reviewId,
  });

  const body = {
    review: {
      review_setting_attributes: {
        structured_extraction_users_required: usersRequired,
      },
    },
  };

  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  });
};
