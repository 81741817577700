import React from 'react';

import styles from './StageProgress.module.css';
import Progress from './Progress';
import Legend from './Legend';
import { Statistics, Stage } from './Home';
import { useReviewFeaturesContext } from 'contexts/ReviewFeaturesProvider';

export interface StageProgressProps extends Statistics {
  stage: Stage;
}

const StageProgress = ({
  complete,
  partiallyComplete,
  consensusRequired,
  notStarted,
  stage,
}: StageProgressProps) => {
  const { getSetting } = useReviewFeaturesContext();
  if (
    stage == 'included' &&
    !getSetting('structured_extraction_users_required')
  ) {
    return null; // Render nothing until review settings retrieved, to avoid obvious re-render
  }

  const singleReviewerExtraction =
    stage == 'included' &&
    getSetting('structured_extraction_users_required') == 1;

  const counts: {
    complete?: number;
    consensusRequired?: number;
    partiallyComplete: number;
    notStarted: number;
  } = { complete, partiallyComplete, consensusRequired, notStarted };

  if (singleReviewerExtraction) {
    counts.partiallyComplete += complete + consensusRequired;
    delete counts.complete;
    delete counts.consensusRequired;
  }

  return (
    <div className={styles.stageProgress}>
      <div>
        <div className={styles.lightHeader}>Team Progress</div>
        <Progress {...counts} />
        <Legend {...counts} stage={stage} />
      </div>
    </div>
  );
};

export default StageProgress;
