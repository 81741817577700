import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import EditableField from '../EditableField';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import SectionTitle from '../SectionTitle';
import {
  Heading,
  Text,
  Icon,
  IconList,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/core';
const RESERVED_FIELD_NAMES: Array<string> = [
  'sponsorship source',
  'country',
  'setting',
  'comments',
  "author's name",
  'institution',
  'email',
  'address',
];

interface Props {
  hasExtractedData?: boolean;
}

const IdentificationPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const additionalFields = useFieldArray<DataExtractionTemplateState>({
    name: 'identification.additionalFields',
  });
  const MAX_LENGTH = 255;

  return (
    <section className={styles.section}>
      {modal}
      <SectionTitle
        title="Identification"
        pendoKey="det-identification"
        ariaLabel="Get help with identification"
      />
      <Popover closeOnBlur={true} renderCloseButton={false}>
        <Text className={styles.PopoverTriggerText}>
          Use this section to define relevant{' '}
          <PopoverTrigger>
            <Text component="span" className={styles.PopoverTrigger}>
              identifying information
            </Text>
          </PopoverTrigger>{' '}
          you need to collect from studies.
        </Text>
        <PopoverContent className={styles.PopoverContentText}>
          <Text>
            This includes details about the study as well as the author’s
            contact details.
          </Text>
          <Text>
            Where possible we will suggest values for these fields when
            extracting to save time.
          </Text>
          <Text>
            Some of these fields can’t be removed from the template, but they
            are not mandatory.
          </Text>
        </PopoverContent>
      </Popover>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Study details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Sponsorship source</Text>
          </li>
          <li>
            <Text variant="medium">Country</Text>
          </li>
          <li>
            <Text variant="medium">Setting</Text>
          </li>
          <li>
            <Text variant="medium">Comments</Text>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          {`Author's contact details`}
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Name</Text>
          </li>
          <li>
            <Text variant="medium">Institution</Text>
          </li>
          <li>
            <Text variant="medium">Email</Text>
          </li>
          <li>
            <Text variant="medium">Address</Text>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h5" component="h4" variant="neutral">
          Additional data
        </Heading>
        {additionalFields.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="identification.additionalFields"
            onDelete={() => {
              const { id } = getValues(
                `identification.additionalFields.${index}`
              );

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => additionalFields.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                additionalFields.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                RESERVED_FIELD_NAMES,
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => additionalFields.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add additional data
        </Link>
      </div>
    </section>
  );
};

export default memo(IdentificationPanel);
