import React, { ReactNode } from 'react';
import { ApolloError } from '@apollo/client';
import LoadingSpinner from 'system/elements/Spinner';
import Box from 'system/base/Box';
import { NakedButton } from 'system/base/Button';
import CollectionTable, {
  Props as CollectionTableProps,
} from 'system/elements/CollectionTable';
import Notice from 'system/elements/Notice';
import { HasID } from 'system/elements/CollectionTable';

export { Columns } from 'system/elements/CollectionTable';

interface Props<T extends HasID = any> extends CollectionTableProps<T> {
  loading?: boolean;
  error?: Error | ApolloError;
  loadMore?: () => void;
  empty?: ReactNode;
}

// defaulting this generic type to any allows us freedom in DataFooter etc.
export default function DataTable<T extends HasID = any>({
  collection,
  loading,
  error,
  loadMore,
  empty,
  ...props
}: Props<T>) {
  return (
    <CollectionTable<T>
      {...props}
      collection={!loading ? collection : null}
      body={
        (!collection || collection.length === 0) &&
        !loading && <DataEmpty empty={empty} />
      }
      header={error && <Notice type="error">{error.message}</Notice>}
      footer={
        (loadMore || loading) && (
          <DataFooter loading={loading} loadMore={loadMore} />
        )
      }
    />
  );
}

function DataFooter({
  loading,
  loadMore,
}: Pick<Props, 'loading' | 'loadMore'>) {
  if (!loading && loadMore) {
    return (
      <Box textAlign="center">
        <NakedButton onClick={() => loadMore()}>View more...</NakedButton>
      </Box>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return null;
}

function DataEmpty({ empty }: Pick<Props, 'empty'>) {
  return (
    <Box textAlign="center">{empty || "Sorry, we didn't find any results"}</Box>
  );
}
