import React from 'react';
import { Link } from './core';

const UpgradeLink = ({ path }: { path: string }) => {
  return (
    <>
      <Link to={path} component="a" variant="primary">
        upgrade
      </Link>
      .
    </>
  );
};

export default UpgradeLink;
