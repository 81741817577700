import React from 'react';
import StageSummary from './StageSummary';
import { Statistics } from './Home';
import { CategoryLink } from './categoryLink';
import { useReviewFeaturesContext } from 'contexts/ReviewFeaturesProvider';

interface ExtractionProps {
  reviewerName: string;
  complete: number;
  stats: Statistics;
  isOpen: boolean;
}

const Extraction = ({
  complete,
  reviewerName,
  stats,
  isOpen,
}: ExtractionProps) => {
  const { getSetting } = useReviewFeaturesContext();
  const isSingleExtractor =
    getSetting('structured_extraction_users_required') === 1;

  const studyInflection = stats?.available === 1 ? 'study' : 'studies';
  const studyCategoryCount = isSingleExtractor
    ? `${stats?.available} ${studyInflection} in extraction`
    : `${stats?.available} ${studyInflection} to extract`;
  const secondaryContent = isSingleExtractor ? null : `${complete} extracted`;

  return (
    <StageSummary
      isOpen={isOpen}
      stage="included"
      title="Extraction"
      secondaryContent={secondaryContent}
      secondaryLink={CategoryLink('included', 'complete')}
      studyCategoryCount={studyCategoryCount}
      studyCategoryLink={CategoryLink('included')}
      permissions={{ consensusRequired: true }}
      reviewerName={reviewerName}
      stats={stats}
    />
  );
};

export default Extraction;
