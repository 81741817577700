import React from 'react';
import nest from 'recompose/nest';
import { ThemeProvider } from 'theme-ui';
import mount from './util/autoMounter';
import exportReactComponents from './legacyBridge';
import OrganizationReviews from './components/admin/OrganizationReviews';
import OrganizationMembers from './components/admin/OrganizationMembers';
import OrganizationInvitations from './components/admin/OrganizationInvitations';
import ReviewStudies from './components/admin/ReviewStudies';
import CitationImportHistory from './pages/CitationImports/CitationImportHistory/CitationImportHistory';
import DataExtractionNavigationItems from './pages/DataExtraction/Navigation/DataExtractionNavigationItems';
import ExportNavigationButton from 'pages/DataExtraction/Navigation/ExportNavigationButton';
import CitationImportSource from 'pages/CitationImports/CitationImportSource';
import { SignupRoleAndStudyLevel } from 'containers/Signup/RoleAndStudyLevel/RoleAndStudyLevel';
import { ViewImportedAssignments } from 'containers/Guidelines/Triage/ViewImportedAssignments';
import { ViewHaltedStudies } from 'containers/Guidelines/Triage/ViewHaltedStudies';
import { ApolloProvider } from 'store/apollo';

import { theme } from 'system/theme';

import AccountCard from 'components/AccountCard';
import Avatar from 'components/Avatar';
import { Badge } from 'components/core';
import DataList from 'components/DataList';
import DeleteReview from 'components/DeleteReview';
import FileUploadDialog from 'components/FileUploadDialog';
import FullTextBulkUploader from 'components/FullTextBulkUploader';

import ManageTags from 'components/ManageTags';
import NewEffectMeasure from 'components/NewEffectMeasure';
import PrivacyAndPermissions from 'components/PrivacyAndPermissions';
import {
  AppFooter,
  CollaboratorExpiredModal,
  ExpiredPlanSelectionModal,
  PrimaryNavigation,
} from 'components/shared';
import MachineLearningPopoverContent from 'pages/StudyList/MachineLearningRelevancyPopover/MachineLearningRelevancyPopover';
import DataExtractionTemplate from 'pages/DataExtraction/DataExtractionTemplate/DataExtractionTemplate';
import {
  QualityAssessmentTemplate,
  QualityAssessmentDefaultTemplate,
} from 'pages/DataExtraction/QualityAssessmentTemplate';
import PrismaActions from 'pages/Prisma/components/PrismaActions/PrismaActions';

import Overview from 'containers/Organizations/Overview';
import Search from 'containers/Organizations/Search';
import Reviews from 'containers/Organizations/Reviews';
import Administrators from 'containers/Organizations/Administrators';
import Author from 'containers/Organizations/Author';
import Authors from 'containers/Organizations/Authors';
import ExportHistory from 'containers/Organizations/ExportHistory';
import ManageInvitations from 'containers/Organizations/ManageInvitations';
import ImportCRS from 'containers/Review/ImportCRS';
import SyncRevmanWeb from 'containers/Review/SyncRevmanWeb';
import { StudyScreening } from 'containers/Review/StudyScreening/StudyScreening';
import SyncRevmanWebResult from 'containers/Review/SyncRevmanWebResult';
import ReviewHome from 'containers/Review/Home/Home';
import ReviewReviewers from 'containers/Review/Reviewers';
import ExtractionBuild from 'containers/Extraction/Build';
import ExtractionExtract from 'containers/Extraction/Extract';
import ExtractionStudyList from 'containers/Extraction/StudyListRoot';
import ExtractionCompare from 'containers/Extraction/Compare';
import ExtractionToolbarNav from 'containers/Extraction/ToolbarNav';
import { StatusBar as ExtractionStatusBar } from 'containers/Extraction/StudyList/StatusBar';
import { RefreshCachedStatus as RefreshStudiesCachedStatus } from 'containers/Extraction/StudyList/RefreshCachedStatus';
import ExportToFile from 'containers/Export/ExportToFile';
import RevmanWebExport from 'containers/Export/RevmanWebExport';
import { Show as GuidelinesShow } from 'containers/Guidelines/Show';
import { AssignToTopics } from 'containers/Guidelines/Triage/AssignToTopics';
import { AssignToQuestions } from 'containers/Guidelines/Triage/AssignToQuestions';
import { PrimaryButton } from 'system/base/Button';
import { Tags as UserTags } from 'containers/users/tags';
import { CityLookup } from 'containers/Signup/CityLookup';
import { AcceptInvitation } from 'containers/Invitations/AcceptInvitation';
import { TopicExperts } from 'containers/Guidelines/TopicExperts/TopicExperts';
import { InvitesLoader as TopicExpertInvitesLoader } from 'containers/Guidelines/TopicExperts/InvitesLoader';
import { ViewStudies } from 'containers/Guidelines/TopicExperts/ViewStudies';
import { CreateComment } from 'containers/Review/Comments/CreateComment';
import { DiscussionsLoader } from 'containers/Comments/DiscussionsLoader';
import { DiscussionLoader } from 'containers/Comments/DiscussionLoader';
import BulkUploadHistory from 'containers/BulkUploadHistory/BulkUploadHistory';
import FullTextBulkUploaderNavigationTabs from 'components/FullTextBulkUploaderNavigationTabs/FullTextBulkUploaderNavigationTabs';
import DemoAlert from 'containers/DemoAlert/DemoAlert';
import { TrialExpiryBanner } from 'containers/Review/StudyScreening/TrialExpiryBanner/TrialExpiryBanner';
import { ExtractionToolSelector } from 'pages/ReviewSettings/components/ExtractionSelector/ExtractionToolSelector';

import './styles/global.scss';
import DuplicateStudyToast from 'pages/StudyList/StudyListToast/DuplicateStudyToast';
import EligibilityCriteria from 'pages/EligibilityCriteria';
import ReviewEligibilityCriteria from 'pages/StudyList/ReviewEligibilityCriteria';
import StudyListMessage from 'pages/StudyList/StudyListMessage/StudyListMessage';
import StudyListExtractionMessage from 'pages/StudyList/StudyListExtractionMessage/StudyListExtractionMessage';
import SingleExtractorBannerMessage from 'pages/StudyList/SingleExtractorBannerMessage/SingleExtractorBannerMessage';
import SingleExtractorModalPrompt from 'pages/StudyList/SingleExtractorModalPrompt/SingleExtractorModalPrompt';
import AutoExclusions from 'pages/AutoExclusions';
import {
  CommonAutomationOptions,
  MedicalAutomationOptions,
} from 'components/NewReview/AutomationOptions';
import AutomationNavigation from 'pages/AutoExclusions/components/AutomationNavigation';
import { EmailField } from 'components/EmailField/EmailField';
import ReviewersRequired from 'pages/ReviewSettings/components/ReviewersRequired/ReviewersRequired';

import { SamlNameFormPage } from 'components/SingleSignOn/SamlNameForm';
import { SamlDiscoveryPage } from 'components/SingleSignOn/SamlDiscoveryPage';
import NewReview from 'components/NewReview/NewReview';
import UpgradeLink from 'components/UpgradeLink';

const WithTheme = (props: any) =>
  React.createElement(ThemeProvider, { theme, ...props });

mount({
  SamlNameFormPage,
  SamlDiscoveryPage,
  AutomationNavigation,
  StudyListMessage,
  StudyListExtractionMessage,
  SingleExtractorBannerMessage,
  SingleExtractorModalPrompt,
  DuplicateStudyToast: DuplicateStudyToast,
  PrismaActions: PrismaActions,
  EligibilityCriteria: EligibilityCriteria,
  ReviewEligibilityCriteria: ReviewEligibilityCriteria,
  CitationImportSource: CitationImportSource,
  CitationImportHistoryPage: CitationImportHistory,
  DataExtractionTemplatePage: DataExtractionTemplate,
  QualityAssessmentTemplatePage: QualityAssessmentTemplate,
  QualityAssessmentDefaultTemplatePage: QualityAssessmentDefaultTemplate,
  DataExtractionNavigationItems: DataExtractionNavigationItems,
  ExportNavigationButton: ExportNavigationButton,
  AdminOrganizationReviews: nest(
    WithTheme,
    ApolloProvider,
    OrganizationReviews
  ),
  AdminOrganizationMembers: nest(
    WithTheme,
    ApolloProvider,
    OrganizationMembers
  ),
  AdminOrganizationInvitations: nest(
    WithTheme,
    ApolloProvider,
    OrganizationInvitations
  ),
  AdminReviewStudies: nest(WithTheme, ApolloProvider, ReviewStudies),
  Badge: Badge,
  BulkUploadHistory: nest(WithTheme, BulkUploadHistory),
  AccountCard: nest(WithTheme, AccountCard),
  Avatar: nest(WithTheme, Avatar),
  DeleteReview: nest(WithTheme, DeleteReview),
  DemoAlert,
  FileUploadDialog: nest(WithTheme, FileUploadDialog),
  FullTextBulkUploader: nest(WithTheme, ApolloProvider, FullTextBulkUploader),
  FullTextBulkUploaderNavigationTabs: nest(
    WithTheme,
    FullTextBulkUploaderNavigationTabs
  ),
  PrimaryNavigation: PrimaryNavigation,
  AppFooter: AppFooter,
  CollaboratorExpiredModal: nest(
    WithTheme,
    ApolloProvider,
    CollaboratorExpiredModal
  ),
  ExpiredPlanSelectionModal: ExpiredPlanSelectionModal,
  MachineLearningPopoverContent: MachineLearningPopoverContent,
  ManageTags: nest(WithTheme, ApolloProvider, ManageTags),
  NewReview: nest(WithTheme, ApolloProvider, NewReview),
  PrivacyAndPermissions: nest(WithTheme, ApolloProvider, PrivacyAndPermissions),
  OrganizationsOverview: nest(WithTheme, ApolloProvider, Overview),
  OrganizationSearch: nest(WithTheme, ApolloProvider, Search),
  Reviews: nest(WithTheme, ApolloProvider, Reviews),
  Administrators: nest(WithTheme, ApolloProvider, Administrators),
  Authors: nest(WithTheme, ApolloProvider, Authors),
  Author: nest(WithTheme, ApolloProvider, Author),
  ExportHistory: nest(WithTheme, ApolloProvider, ExportHistory),
  ManageInvitations: nest(WithTheme, ApolloProvider, ManageInvitations),
  ImportCRS: nest(WithTheme, ApolloProvider, ImportCRS),
  SyncRevmanWeb: nest(WithTheme, ApolloProvider, SyncRevmanWeb),
  SyncRevmanWebResult: nest(WithTheme, ApolloProvider, SyncRevmanWebResult),
  StudyScreening: nest(WithTheme, ApolloProvider, StudyScreening),
  ExtractionBuild: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ExtractionBuild
  ),
  ExtractionExtract: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ExtractionExtract
  ),
  ExtractionStatusBar: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ExtractionStatusBar
  ),
  RefreshStudiesCachedStatus: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    RefreshStudiesCachedStatus
  ),
  ExtractionStudyList: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ExtractionStudyList
  ),
  ExtractionCompare: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ExtractionCompare
  ),
  ExtractionToolbarNav: ExtractionToolbarNav,
  GuidelinesShow: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    GuidelinesShow
  ),
  GuidelinesTriageAssignToTopics: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    AssignToTopics
  ),
  GuidelinesTriageAssignToQuestions: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    AssignToQuestions
  ),
  GuidelinesTriageViewImportedAssignments: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ViewImportedAssignments
  ),
  GuidelinesTriageViewHaltedStudies: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ViewHaltedStudies
  ),
  GuidelinesTopicExperts: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    TopicExperts
  ),
  GuidelinesInvites: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    TopicExpertInvitesLoader
  ),
  GuidelinesTopicExpertsStudies: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ViewStudies
  ),
  CreateComment: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    CreateComment
  ),
  DiscussionsLoader: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    DiscussionsLoader
  ),
  DiscussionLoader: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    DiscussionLoader
  ),
  ExportToFile: nest(WithTheme, React.StrictMode, ExportToFile),
  RevmanWebExport: nest(WithTheme, React.StrictMode, RevmanWebExport),
  ReviewHome: nest(WithTheme, ApolloProvider, React.StrictMode, ReviewHome),
  ReviewReviewers: nest(
    WithTheme,
    ApolloProvider,
    React.StrictMode,
    ReviewReviewers as any
  ),
  PrimaryButton: PrimaryButton,
  CityLookup: nest(WithTheme, CityLookup),
  SignupRoleAndStudyLevel: nest(WithTheme, SignupRoleAndStudyLevel),
  UserTags: nest(WithTheme, UserTags),
  AcceptInvitation: nest(WithTheme, AcceptInvitation),
  TrialExpiryBanner: nest(WithTheme, ApolloProvider, TrialExpiryBanner),
  ExtractionToolSelector: ExtractionToolSelector,
  AutoExclusions: AutoExclusions,
  CommonAutomationOptions: CommonAutomationOptions,
  MedicalAutomationOptions: MedicalAutomationOptions,
  ReviewersRequired: ReviewersRequired,
  EmailField,
  UpgradeLink,
});

exportReactComponents({
  DataList: nest(WithTheme, DataList),
  NewEffectMeasure: nest(WithTheme, ApolloProvider, NewEffectMeasure),
});
