import React from 'react';
import { PENDO_REVIEW_SETTING_PREFIX } from '../ExtractionToolSelector';
import styles from './UnableToSwitchModal.module.scss';
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'components/core';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const UnableToSwitchModal = ({ onConfirm, onClose }: Props) => {
  const supportLink =
    'https://support.covidence.org/help/how-to-do-single-reviewer-extraction-in-extraction-1#what_if_i_already_started';

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalHeader>Unable to switch to 1 reviewer</ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <Text>
          Looks like a 2nd reviewer has started extraction on at least one of
          your study forms.
        </Text>
        <Text>
          {`Unfortunately we don't support a hybrid dual and single workflow yet.`}
        </Text>
        <Text>
          {`However, if you'd like to try a single reviewer workflow, you can `}
          <Link to={supportLink} variant="primary" external>
            remove the 2nd reviewer
          </Link>
          {` from all study forms.`}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          type="brand"
          onClick={onConfirm}
          data-pendo-key={
            PENDO_REVIEW_SETTING_PREFIX +
            'single_extractor_notification_confirmation'
          }
        >
          Okay, got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UnableToSwitchModal;
